import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Instruction } from "../../client/types";
import { BotsList } from "../../client/types";
import { useCreateBot } from "../../hooks/useCreateBot";
import { useListEngines } from "../../hooks/useListEngines";

export const NewBot = ({ onNewBot }: { onNewBot: (bot: BotsList) => void }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { createBot, loading } = useCreateBot();
  const organization_id =
    localStorage.getItem("organization_id") ?? "fractalriver";
  const [engine_id, setEngineId] = useState("");
  const [botName, setBotName] = useState("");
  const [apiToken, setApiToken] = useState("");
  const [showApiToken, setShowApiToken] = useState(false);
  const { engines } = useListEngines();
  const initialRef = useRef(null);
  const finalRef = useRef(null);

  const handleSave = async () => {
    try {
      const engine = engines.find((engine) => engine.id === engine_id);
      const { ASK, ROLE, GENERAL_CONTEXT, EXAMPLES } = JSON.parse(
        engine.instructions
      );
      const instructions: Instruction = {
        ask: ASK,
        role: ROLE,
        general_context: GENERAL_CONTEXT,
        examples: EXAMPLES.map((example: any) => ({
          input: example.INPUT,
          output: example.OUTPUT,
        })),
      };

      const newBot = await createBot({
        organization_id,
        engine_id,
        name: botName,
        avatar: "",
        description: "Default version",
        is_default: true,
        instructions,
        api_token: apiToken, // Add API token to the payload
      });

      onNewBot(newBot);

      onClose();
    } catch (error) {
      console.error("Error creating agent:", error);
    }
  };

  const handleCancel = () => {
    setBotName("");
    setEngineId("");
    setApiToken("");
    onClose();
  };

  return (
    <>
      <Button onClick={onOpen}>New agent</Button>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={handleCancel}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add new agent</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Agent name</FormLabel>
              <Input
                ref={initialRef}
                value={botName}
                placeholder="Molly"
                onChange={(e) => setBotName(e.target.value)}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Engine</FormLabel>
              <Select
                placeholder="Select engine"
                value={engine_id}
                onChange={(e) => setEngineId(e.target.value)}
              >
                {engines.map((engine) => (
                  <option key={engine.id} value={engine.id}>
                    {engine.name} ({engine.id})
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>API Token</FormLabel>
              <InputGroup>
                <Input
                  type={showApiToken ? "text" : "password"}
                  value={apiToken}
                  placeholder="Enter API token"
                  onChange={(e) => setApiToken(e.target.value)}
                />
                <InputRightElement>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => setShowApiToken(!showApiToken)}
                  >
                    {showApiToken ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
          </ModalBody>

          <ModalFooter justifyContent="flex-end">
            <Button
              onClick={handleCancel}
              colorScheme="brand"
              variant="outline"
              mr={3}
              isDisabled={loading}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              isDisabled={loading || !botName || !engine_id || !apiToken}
              colorScheme="brand"
            >
              {loading ? "Creating your bot" : "Save"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
