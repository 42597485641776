import {
    Box,
    Button,
    Collapse,
    HStack,
    Icon,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Stack,
    Text,
    useDisclosure
  } from "@chakra-ui/react";
  import { FiChevronDown, FiMessageCircle, FiMoreVertical } from "react-icons/fi";
  import { SidebarButton } from "../Sidebar/SidebarButton";
  import { useListBots } from "../../hooks/useListBots"; // Import the hook to fetch bots
import { FaRobot } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
  export const BotsCollapse = ({
    isCollapsed,
  }: {
    isCollapsed?: boolean;
  }) => {
    const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
    const { bots = [], loading } = useListBots(); // Fetch bots data
    const navigate = useNavigate();
    const location = useLocation();

    const handleBotClick = (id: string) => {
        navigate(`/bots/${id}`);
        window.location.reload();
        }


    if (isCollapsed) {
      return (
        <SidebarButton
          leftIcon={<FaRobot />}
          isActive={isOpen}
          onClick={onToggle}
          isCollapsed={isCollapsed}
        >
          My Agents
        </SidebarButton>
      );
    }
  
    return (
      <Box>
        <Button
          variant="tertiary"
          onClick={onToggle}
          justifyContent="space-between"
          width="full"
        >
          <HStack spacing="3" onClick={() => navigate('/home')}>
            <Icon as={FaRobot} />
            <Text as="span">My Agents</Text>
          </HStack>
          <PopoverIcon isOpen={isOpen} />
        </Button>
        <Collapse in={isOpen} animateOpacity>
          <Stack
            spacing="1"
            alignItems="stretch"
            ps="8"
            py="1"
            maxHeight="30vh"
            overflowY="auto"
          >
            {loading ? (
              null
            ) : (
              bots.map((bot) => (
                <HStack key={bot.id} justifyContent="space-between" width="full">
                  <Button variant="tertiary" justifyContent="start" onClick={

                    () => handleBotClick(bot.id)
                  }
                  isActive={location.pathname.toLowerCase() === `/bots/${bot.id}`}
                  >

                    {bot.name}
                  </Button>
                 
                </HStack>
              ))
            )}
          </Stack>
        </Collapse>
      </Box>
    );
  };
  
  export const PopoverIcon = (props: { isOpen: boolean }) => {
    const iconStyles = {
      transform: props.isOpen ? "rotate(-180deg)" : undefined,
      transition: "transform 0.2s",
      transformOrigin: "center",
    };
    return <Icon aria-hidden as={FiChevronDown} __css={iconStyles} />;
  };