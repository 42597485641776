import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FiMoreVertical } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { APP_VERSION } from "../../utils/constants";
export const QuickMenu = () => {
  const navigate = useNavigate();
  const organization = localStorage.getItem("organization");
  const handleNavClick = (path: string) => {
    navigate(path);
  };

  const handleLogout = () => {
    // Remove the access_token from localStorage
    sessionStorage.removeItem("access_token");
    // Redirect to the login page or home page
    handleNavClick("/login");
  };

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<FiMoreVertical />}
        variant={"ghost"}
      />
      <MenuList>
        <MenuGroup title="Profile">
          <MenuItem onClick={() => handleNavClick("/settings")}>
            My Account
          </MenuItem>
          <MenuItem onClick={() => handleLogout()}>Log out</MenuItem>

          <MenuDivider />

          <Box bg="bg.surface" boxShadow="sm" borderRadius="lg" flex="1" p="4">
            <VStack align="start" spacing="4">
              <Text fontSize="md" color="gray.300">
                Organization: {organization}
              </Text>
              <Text fontSize="md" color="gray.300">
                App Version:{" "}
                {parseInt(APP_VERSION[0]) < 1
                  ? `${APP_VERSION} (Beta)`
                  : APP_VERSION}
              </Text>
            </VStack>
          </Box>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};
