import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  StackDivider,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Bot as BotType } from "../../client/types";
import { useDeleteBot } from "../../hooks/useDeleteBot";
import { useUpdateBot } from "../../hooks/useUpdateBot";
import { Dropzone } from "../Common/Dropzone";

export const BotSetup = ({
  Bot,
  onChange,
}: {
  Bot: BotType;
  onChange: (newName: string) => void;
}) => {
  const botId = Bot.id;
  const [newBotName, setBotName] = useState(Bot.name);
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inputBotId, setInputBotId] = useState("");
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const { deleteBot, loading: deleteLoading } = useDeleteBot();
  const { updateBot, loading: updateLoading } = useUpdateBot();

  const handleDelete = async () => {
    if (inputBotId === "DELETE") {
      const success = await deleteBot(botId);
      if (success) {
        onClose();
        toast({
          title: "Bot deleted.",
          description: `The bot ${Bot.name} has been deleted successfully.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        navigate("/home");
      }
    } else {
      toast({
        title: "Error",
        description: "Please enter 'DELETE' to confirm deletion.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleUpdateBot = async () => {
    if (newBotName) {
      await updateBot(botId, newBotName);
      onChange(newBotName);
      navigate("/bots/" + botId);
    } else {
      toast({
        title: "Error",
        description: "Failed to update bot name.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const goBack = () => {
    navigate(`/bots/${botId}?setup=false`);
  };

  return (
    <Container py={{ base: "4", md: "8" }} minH="80vh" minW="135vh">
      <Stack spacing="5">
        <Stack
          spacing="4"
          direction={{ base: "column", sm: "row" }}
          justify="space-between"
        ></Stack>
        <Stack spacing="5" divider={<StackDivider />}>
          <FormControl id="name">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <FormLabel variant="inline">Agent name</FormLabel>
              <Input
                maxW={{ md: "3xl" }}
                defaultValue={Bot.name}
                onChange={(e) => setBotName(e.target.value)}
              />
            </Stack>
          </FormControl>
          <FormControl id="engine">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <FormLabel variant="inline">Engine</FormLabel>
              <Input
                type="text"
                maxW={{ md: "3xl" }}
                defaultValue={Bot.engine.name}
                readOnly
                variant="ghost"
                textColor="gray"
              />
            </Stack>
          </FormControl>
          

          <Flex justify="space-between">
            <Button colorScheme="red" mt="1" onClick={onOpen}>
              Delete agent
            </Button>
            <Flex>
              <Divider orientation="horizontal" variant="ghost" />
              <Button
                mt="1"
                onClick={goBack}
                variant="ghost"
                _hover={{ bg: "transparent" }}
              >
                Cancel
              </Button>
              <Divider orientation="horizontal" variant="ghost" />
              <Button
                mt="1"
                onClick={handleUpdateBot}
                isDisabled={
                  newBotName === Bot.name || updateLoading ? true : false
                }
              >
                {updateLoading ? "Saving..." : "Save"}
              </Button>
            </Flex>
          </Flex>
        </Stack>
      </Stack>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Bot
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
              <FormControl mt={4}>
                <FormLabel>Enter DELETE to confirm</FormLabel>
                <Input
                  value={inputBotId}
                  onChange={(e) => setInputBotId(e.target.value)}
                />
              </FormControl>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onClose}
                variant="ghost"
                isDisabled={deleteLoading}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={handleDelete}
                ml={3}
                isDisabled={deleteLoading}
              >
                {deleteLoading ? "Deleting..." : "Delete"}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Container>
  );
};
