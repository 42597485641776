import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { updateBotVersion as updateBotVersionType } from "../client/types";
import { API_URL } from "../utils/constants";
import { loadToken } from "../utils/validateToken";

export const useUpdateBotVersion = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();

  const updateBotVersion = async (bot_version_update: updateBotVersionType) => {
    setLoading(true);
    setError(null);

    try {
      const body = JSON.stringify({
        name: bot_version_update.name,
        description: bot_version_update.description,
        is_default: bot_version_update.is_default,
        instructions: JSON.stringify(bot_version_update.instructions),
      });
      const token = loadToken() || "";
      const response = await fetch(
        `${API_URL}/api/v1/bots/bots/${bot_version_update.bot_id}/versions/${bot_version_update.version_id}`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
          },
          body: body,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update bot version");
      }

      toast({
        title: "Bot updated",
        description: `The bot version has been updated successfully.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      window.location.reload();
      return response.json();
    } catch (error: any) {
      setError(error.message);
      toast({
        title: "Failed to update bot",
        description: error.message || "Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return { updateBotVersion, loading, error };
};
