import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { API_URL } from "../utils/constants";
import { loadToken } from "../utils/validateToken";

interface updateUserInfo {
  user_update_request: {
    full_name: string | null;
    phone_number?: string | null;
  };
}

interface updateUserPassword {
  user_password_request: {
    old_password: string;
    password: string;
  };
}

export const useUpdateUser = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();

  const token = loadToken() || "";

  const updateUserInfo = async (userData: updateUserInfo) => {
    setLoading(true);
    setError(null);

    try {
      if (userData.user_update_request.full_name === "") {
        throw new Error("Name cannot be empty.");
      }

      const response = await fetch(`${API_URL}/api/v1/user/info`, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        throw new Error("Failed to update profile. Please try again.");
      }

      const data = await response.json();

      toast({
        title: "Profile updated.",
        description: "Your profile has been successfully updated.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      return data;
    } catch (error: any) {
      setError(error.message);
      toast({
        title: "Update failed.",
        description:
          error.message || "There was an error updating your profile.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const updateUserPassword = async (userData: updateUserPassword) => {
    setLoading(true);
    setError(null);

    try {
      if (userData.user_password_request.password === "") {
        throw new Error("Password cannot be empty.");
      }

      const response = await fetch(`${API_URL}/api/v1/user/password`, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(userData),
      });

      if (!response.ok) {
        const errorMessage = (await response.json()).detail;
        throw new Error(errorMessage);
      }

      const data = await response.json();

      toast({
        title: "Password updated.",
        description: "Your password has been successfully updated.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      return data;
    } catch (error: any) {
      setError(error.message);
      toast({
        title: "Update failed.",
        description:
          error.message || "There was an error updating your password.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return { updateUserInfo, updateUserPassword, loading, error };
};
