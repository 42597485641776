import { useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { API_URL } from "../utils/constants";
import { loadToken } from "../utils/validateToken";
export const useListBotsVersions = (botId: string) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [botVersions, setbotVersions] = useState<any[]>([]); // Local state to store fetched bots
  const toast = useToast();


  const listBotsVersions = async () => {
    if (botVersions.length > 0) {
      // If bots are already loaded, no need to fetch again
      return botVersions;
    }

    setLoading(true);
    setError(null);
    try {
      const token = loadToken() || "";
      if (!token) {
        throw new Error("Session expired");
      }
      const response = await fetch(
        `${API_URL}/api/v1/bots/bots/${botId}/versions`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch bots");
      }

      const data = await response.json();
      setbotVersions(data); // Cache the data in local state
      return data;
    } catch (error: any) {
      setError(error.message);
      toast({
        title: "Failed to get bots versions",
        description: error.message || "Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  // Optional useEffect to automatically fetch bots if not loaded yet
  useEffect(() => {
    if (botVersions.length === 0) {
      listBotsVersions(); // Fetch bots if not already cached
    }
  }, []); // Runs only once when the component mounts

  return { listBotsVersions, error, loading, botVersions }; // Expose bots for access in the component
};
