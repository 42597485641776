import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { BotVersion } from "../../client/types";
import { useCreateBotVersion } from "../../hooks/useCreateBotVersion";
import { useGetBot } from "../../hooks/useGetBot";
import { useGetBotVersion } from "../../hooks/useGetBotVersion";
import { useListBotsVersions } from "../../hooks/useListBotsVersions";
import { useListEngines } from "../../hooks/useListEngines";
export const NewVersion = ({
  botId,
  onNewBotVersion,
}: {
  botId: string;
  onNewBotVersion: (bot: BotVersion) => void;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const { createBotVersion, loading } = useCreateBotVersion();

  // Fetch the list of bot versions
  const { botVersions } = useListBotsVersions(botId);
  const { bot } = useGetBot(botId);
  const { engines } = useListEngines();
  const engine = engines.find((engine) => engine.id === bot?.engine.id);
  const engine_instructions = engine?.instructions;

  // State to manage form inputs and selected version
  const [versionName, setVersionName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedVersionId, setSelectedVersionId] = useState<string | null>(
    null
  );
  const [selectedVersionData, setSelectedVersionData] = useState<any>(null);

  const { getBotVersion } = useGetBotVersion(
    botId,
    selectedVersionId === "engine-prompt" ? "" : selectedVersionId || ""
  );
  const latestVersion = botVersions.find((v) => v.is_default) || {};
  // Fetch the selected version's data when selectedVersionId changes
  useEffect(() => {
    const fetchVersionData = async () => {
      if (selectedVersionId && selectedVersionId !== "engine-prompt") {
        try {
          const versionData = await getBotVersion();
          setSelectedVersionData(versionData);
        } catch (error) {
          console.error("Error fetching version:", error);
        }
      }
      if (selectedVersionId === "engine-prompt") {
        setSelectedVersionData(engine_instructions);
      }
    };
    fetchVersionData();
  }, [selectedVersionId, getBotVersion]);

  const handleSave = async () => {
    try {
      // Create the new bot version with either default instructions or copied instructions
      const defaultVersion = botVersions.find((v) => v.is_default);
      const newBotVersion = await createBotVersion({
        bot_id: botId,
        name: versionName,
        description: description,
        is_default: false,
        instructions: selectedVersionData.instructions,
      });

      // Pass the new bot version to the parent component
      onNewBotVersion(newBotVersion);

      // Close the modal
      onClose();
    } catch (error) {
      console.error("Error creating version:", error);
    }
  };

  return (
    <>
      <Button onClick={onOpen}>New version</Button>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create new version</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Version name</FormLabel>
              <Input
                ref={initialRef}
                value={versionName}
                placeholder={`${latestVersion.name || "Version"} ${
                  botVersions.length + 1
                }`}
                onChange={(e) => setVersionName(e.target.value)}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Description</FormLabel>
              <Input
                value={description}
                placeholder="This is a new version"
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Based on</FormLabel>
              <Select
                placeholder="Select version"
                value={selectedVersionId || ""}
                onChange={(e) => setSelectedVersionId(e.target.value)}
              >
                {botVersions.map((botVersion) => (
                  <option key={botVersion.id} value={botVersion.id}>
                    {botVersion.is_default
                      ? botVersion.name + " (Default)"
                      : botVersion.name}
                  </option>
                ))}
                <option value="engine-prompt">
                  Use engine prompt ({engine?.id})
                </option>
              </Select>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onClose}
              colorScheme="brand"
              variant="outline"
              isDisabled={loading}
              mr={3}
            >
              Cancel
            </Button>
            <Button
              colorScheme="brand"
              onClick={handleSave}
              isDisabled={
                loading || !versionName || !description || !selectedVersionId
              }
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
