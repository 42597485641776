import { DeleteIcon, InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Textarea,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BotVersion, Example } from "../../client/types";

export const MarkConfig = ({
  version,
  setInstructions,
}: {
  version: BotVersion | null;
  setInstructions: (instructions: BotVersion["instructions"]) => void;
}) => {
  const [instructions, setLocalInstructions] = useState<
    BotVersion["instructions"]
  >({
    role: version?.instructions.role || "",
    general_context: version?.instructions.general_context || "",
    ask: version?.instructions.ask || "",
    examples: version?.instructions.examples || [],
  });

  // Synchronize parent instructions when local instructions change
  useEffect(() => {
    setInstructions(instructions);
  }, [instructions, setInstructions]);

  const handleFieldChange = (
    field: keyof BotVersion["instructions"],
    value: string
  ) => {
    setLocalInstructions((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleExampleChange = (
    index: number,
    field: keyof Example,
    value: string
  ) => {
    setLocalInstructions((prev) => {
      const updatedExamples = [...prev.examples];
      updatedExamples[index] = {
        ...updatedExamples[index],
        [field]: value,
      };
      return {
        ...prev,
        examples: updatedExamples,
      };
    });
  };

  const addExample = () => {
    setLocalInstructions((prev) => ({
      ...prev,
      examples: [...prev.examples, { input: "", output: "" }],
    }));
  };

  const removeExample = (index: number) => {
    setLocalInstructions((prev) => {
      const updatedExamples = prev.examples.filter((_, i) => i !== index);
      return {
        ...prev,
        examples: updatedExamples,
      };
    });
  };

  return (
    <Box p={5} borderWidth={1} borderRadius="lg">
      <VStack spacing={4}>
        {/* Role Input */}
        <FormControl id="role" isRequired>
          <FormLabel>Role</FormLabel>
          <Textarea
            value={instructions.role}
            fontSize="sm"
            onChange={(e) => handleFieldChange("role", e.target.value)}
          />
        </FormControl>

        {/* General Context Input */}
        <FormControl id="generalContext" isRequired>
          <FormLabel>General Context</FormLabel>
          <Textarea
            value={instructions.general_context}
            fontSize="sm"
            onChange={(e) =>
              handleFieldChange("general_context", e.target.value)
            }
          />
        </FormControl>

        {/* Ask Input */}
        <FormControl id="ask" isRequired>
          <FormLabel>Ask</FormLabel>
          <Textarea
            fontSize="sm"
            value={instructions.ask}
            onChange={(e) => handleFieldChange("ask", e.target.value)}
          />
        </FormControl>

        {/* Examples Section */}
        <Box w="100%">
          <FormLabel>
            Examples
            <Tooltip
              label="Add an example for your bot input and output"
              aria-label="A tooltip"
            >
              <sup>
                <InfoIcon cursor="pointer" ml={1} />
              </sup>
            </Tooltip>
          </FormLabel>
          {instructions.examples.map((example, index) => (
            <Box key={index} mb={4} p={4} borderWidth={1} borderRadius="md">
              <HStack spacing={4}>
                {/* Input Example */}
                <FormControl id={`exampleInput-${index}`}>
                  <Textarea
                    fontSize="sm"
                    value={example.input}
                    onChange={(e) =>
                      handleExampleChange(index, "input", e.target.value)
                    }
                    placeholder="Sample input"
                  />
                </FormControl>

                {/* Output Example */}
                <FormControl id={`exampleOutput-${index}`}>
                  <Textarea
                    fontSize="sm"
                    value={example.output}
                    onChange={(e) =>
                      handleExampleChange(index, "output", e.target.value)
                    }
                    placeholder="Sample output"
                  />
                </FormControl>

                {/* Remove Example Button */}
                <IconButton
                  aria-label="Remove example"
                  icon={<DeleteIcon />}
                  colorScheme="red"
                  onClick={() => removeExample(index)}
                />
              </HStack>
            </Box>
          ))}

          <Button onClick={addExample} colorScheme="blue">
            Add Example
          </Button>
        </Box>
      </VStack>
    </Box>
  );
};
