import { Flex } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { BotHome } from "../components/Bot/BotHome";
import { SideBar } from "../components/Sidebar/SideBar";

export const Bot = () => {
  const { botId, mode, assistant_id } = useParams<{
    botId: string;
    mode: string;
    assistant_id: string;
  }>();
  return (
    <Flex direction="row" minH="100vh" minW="50vw">
      <Flex flex="1">
        <SideBar bot_version={assistant_id ?? ""}/>
      </Flex>
      <Flex flex="4" justify="left" maxW="100vw">
        <BotHome
        
        />
      </Flex>
    </Flex>
  );
};
