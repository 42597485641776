import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BotVersion } from "../../client/types";
import { useListBotsVersions } from "../../hooks/useListBotsVersions";
import { BotVersionsTable } from "./BotVersionsTable";
import { NewVersion } from "./NewVersion";

export const BotVersions = ({
  id,
}: {
  id: string;
}) => {
  const botId = id;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedVersion, setSelectedVersion] = useState<string | null>(null); // State for selected version ID
  const { botVersions: fetchedBotVersions } = useListBotsVersions(botId);
  const [botVersions, setBotVersions] = useState<BotVersion[]>([]);
  const [chatModeState, setChatModeState] = useState<boolean>(false);

  useEffect(() => {
    if (fetchedBotVersions && fetchedBotVersions.length > 0) {
      setBotVersions(fetchedBotVersions);
    }
  }, [fetchedBotVersions]);

  const handleNewBotVersion = (newBotVersion: BotVersion) => {
    setBotVersions((prevVersions) => [...prevVersions, newBotVersion]);
  };

  const handleUpdatedBotVersion = (updatedBotVersion: BotVersion) => {
    setBotVersions((prevVersions) =>
      prevVersions.map((version) =>
        version.version === updatedBotVersion.version
          ? updatedBotVersion
          : version
      )
    );
  };

  return (
    <Flex direction="column" minH="80vh" maxW="100vw" minW="prose">
      <Flex
        justify="space-between"
        align="center"
        p={4}
        borderBottom="1px solid #e2e8f0"
      >
        <Text fontSize="xl" fontWeight="bold">
          {chatModeState ? "Chat Assistant" : "Agent versions"}
        </Text>
        {isEditing ? (
          <Button onClick={() => setIsEditing(false)} colorScheme="red">
            Cancel
          </Button>
        ) : (
          <NewVersion botId={botId} onNewBotVersion={handleNewBotVersion} />
        )}
      </Flex>

      <Flex flex="1">
        <Box flex="1">
          <BotVersionsTable
            id={botId}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            selectedVersion={selectedVersion} // Pass down selected version ID
            setSelectedVersion={setSelectedVersion} // Pass down setter for selected version ID
            botVersions={botVersions}
            onUpdatedBotVersion={handleUpdatedBotVersion}

          />
        </Box>
      </Flex>
    </Flex>
  );
};
