import { Box, Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BotsList } from "../../client/types";
import { useListBots } from "../../hooks/useListBots";
import { MyBotsTable } from "./MyBotsTable";
import { NewBot } from "./NewBot";

import { useToast } from "@chakra-ui/react";

export const MyBotsHome = () => {
  // Toast to display error messages
  const toast = useToast();

  // State to manage the list of bots
  const [bots, setBots] = useState<BotsList[]>([]);

  // Fetch the list of bots
  const { bots: fetchedBots, error } = useListBots();

  // Use useEffect to update the bots list when fetchedBots change
  useEffect(() => {
    if (fetchedBots && fetchedBots.length > 0) {
      setBots(fetchedBots);
    } else {
      setBots([]);
    }
  }, [fetchedBots]); // This will only run when fetchedBots changes

  // Function to handle the addition of a new bot to the list
  const handleNewBot = (newBot: BotsList) => {
    setBots((prevBots) => [...prevBots, newBot]);
    if (error) {
      // Clear the error message if there was one
      window.location.reload();
    }
  };

  return (
    <Flex direction="column" minW="60vw">
      <Flex
        justify="space-between"
        align="center"
        p={4}
        borderBottom="1px solid #e2e8f0"
      >
        <Text fontSize="xl" fontWeight="bold">
          My agents
        </Text>
        <NewBot onNewBot={handleNewBot} />
      </Flex>

      <Flex flex="1">
        <Box flex="1">
          {fetchedBots.length === 0 && error ? (
            <Text textAlign="center" mt={8}>
              {error}
            </Text>
          ) : (
            <MyBotsTable bots={bots} />
          )}
        </Box>
      </Flex>
    </Flex>
  );
};
