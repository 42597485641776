import {
  Box,
  BoxProps,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useUpdateUser } from "../../hooks/useUpdateUser";

const organization = localStorage.getItem("organization");

export const AddressCard = (props: BoxProps) => {
  const { updateUserInfo, loading, error } = useUpdateUser();

  // Initialize state with localStorage values or default values
  const [formData, setFormData] = useState({
    fullName:
      localStorage.getItem("full_name") === "undefined" ||
      localStorage.getItem("full_name") === "null"
        ? ""
        : localStorage.getItem("full_name") ?? "",
    phoneNumber:
      localStorage.getItem("phone_number") === "undefined" ||
      localStorage.getItem("phone_number") === "null"
        ? ""
        : localStorage.getItem("phone_number") ?? "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const userData = {
      user_update_request: {
        full_name: formData.fullName,
        phone_number: formData.phoneNumber,
      },
    };

    await updateUserInfo(userData);
    localStorage.setItem("full_name", userData.user_update_request.full_name);

    localStorage.setItem(
      "phone_number",
      userData.user_update_request.phone_number
    );
  };

  return (
    <Box
      as="form"
      bg="bg.surface"
      boxShadow="sm"
      borderRadius="lg"
      onSubmit={handleSubmit}
      {...props}
    >
      <Stack
        spacing="5"
        px={{ base: "4", md: "6" }}
        py={{ base: "5", md: "6" }}
      >
        <Stack spacing="6" direction={{ base: "column", md: "row" }}>
          <FormControl id="fullName">
            <FormLabel>Full Name</FormLabel>
            <Input value={formData.fullName} onChange={handleChange} />
          </FormControl>
          <FormControl id="organization">
            <FormLabel>Organization</FormLabel>
            <Input
              value={organization || ""}
              isReadOnly
              variant="ghost"
              color="fg.muted"
            />
          </FormControl>
          <FormControl id="phoneNumber">
            <FormLabel>Phone Number</FormLabel>
            <Input value={formData.phoneNumber} onChange={handleChange} />
          </FormControl>
        </Stack>
      </Stack>
      <Flex direction="row-reverse" py="4" px={{ base: "4", md: "6" }}>
        <Button type="submit" isLoading={loading}>
          Save
        </Button>
      </Flex>
      {error && <Box color="red.500">{error}</Box>}
    </Box>
  );
};
